/* eslint-disable no-param-reassign */
export const formatter = (time) => new Date(time).toLocaleTimeString(
  window.navigator.language,
  {
    hour: 'numeric',
    minute: 'numeric',
  },
);

export const formatTime = (start, end) => {
  start = formatter(start).toUpperCase();
  end = formatter(end).toUpperCase();
  return `${start} - ${end}`;
};

export const formatDate = (date, options) => new Date(date).toLocaleDateString(
  window.navigator.language,
  options,
);

export const getStandardDate = (timestamp) => {
  const month = formatDate(timestamp, { month: 'long' });
  const date = formatDate(timestamp, { day: '2-digit' });
  const year = formatDate(timestamp, { year: 'numeric' });
  return `${date} ${month}, ${year}`;
};

export const formatedDateRange = (item) => {
  const startDay = item.startDate.substring(8, 10);
  const m1 = new Date(item.startDate).toLocaleDateString(window.navigator.language, {
    month: 'long',
  });
  const endDay = item.endDate.substring(8, 10);
  const m2 = new Date(item.endDate).toLocaleDateString(window.navigator.language, {
    month: 'long',
  });
  return `${startDay} ${m1} - ${endDay} ${m2}`;
};

export const compareDates = (dateOne, dateTwo) => {
  const currentTime = new Date(dateOne).valueOf();
  const expiryTime = new Date(dateTwo).valueOf();
  return currentTime > expiryTime;
};

export const timeZoneAbbreviated = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);
  if (tz.includes(' ')) {
    return tz
      .split(' ')
      .map(([first]) => first)
      .join('');
  }
  return tz;
};
