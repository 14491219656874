/* eslint-disable react/prop-types */
import React from 'react';
import { formatTime, timeZoneAbbreviated } from '../../helpers/date-time-formatter';

function Timezones(props) {
  const { content } = props;
  const tz = timeZoneAbbreviated();

  return (
    <>
      <p className="timezones">
        {formatTime(content.startTime ? content.startTime : content.startDate, content.endTime ? content.endTime : content.endDate)}
        &nbsp;
        <span className="fw-bold">{tz}</span>
      </p>
    </>
  );
}

export default Timezones;
